import React, { useEffect, useState } from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { convertToBgImage } from 'gbimage-bridge'
import BackgroundImg from 'gatsby-background-image'
import Pagina from '../components/Pagina'
import { useInView } from 'react-intersection-observer'
import { motion, useAnimation } from 'framer-motion'
import { useIntl } from 'gatsby-plugin-react-intl'
import parse from 'html-react-parser'

// Scss
import './styles/gente.scss'

// Icons
import icon1 from '../images/icon/gente/Gente_1.svg'
import icon2 from '../images/icon/gente/Gente_2.svg'
// import certificado from '../images/icon/gente/certificado.svg'
// import selo from '../images/icon/gente/selo.svg'
import paper from '../images/icon/home/paper.svg'
import canalDenuncias from '../images/icon/gente/canal-denuncias.svg'

// Assets
import cracha from '../images/nossa-essencia/cracha.png'
import crachaEnglish from '../images/nossa-essencia/cracha-english.png'
import crachaEspanhol from '../images/nossa-essencia/cracha-espanhol.png'

// const Paralax = ({ paralax, paralaxMobile }) => {
//   const image = getImage(paralax)
//   const bgImage = convertToBgImage(image)
//   const imageMobile = getImage(paralaxMobile)
//   const bgImageMobile = convertToBgImage(imageMobile)
//   const intl = useIntl()

// return (
//     <>
//       <div className="paralaxGente">
//         <BackgroundImg
//           {...bgImage}
//           Tag="div"
//           className="paralaxImage d-lg-block d-none"
//         >
//           <div className="container h-100">
//             <div className="container h-100 d-flex justify-content-lg-end justify-content-center ">
//               <div className=" h-100 d-flex align-items-center">
//                 <div className="w-100">
//                   <h1 className="pb-4 text-white">{parse(intl.formatMessage({ id: 'paginas.gente.paralax.title' }))}</h1>
//                   <div className='d-flex'>
//                     <img src={selo} className='pr-3' width={'135'}></img>
//                     <p className="line-height-2 text-white">{parse(intl.formatMessage({ id: 'paginas.gente.paralax.p' }))}
//                     </p>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </BackgroundImg>
//         <BackgroundImg
//           {...bgImageMobile}
//           Tag="div"
//           className="paralaxImage d-lg-none d-block"
//         >
//           <div className="container h-100">
//             <div className="container h-100 d-flex justify-content-lg-end">
//               <div className=" h-100 d-flex align-items-center">
//                 <div className="w-100">
//                   <h1 className="paddingCustom text-white pt-0">{parse(intl.formatMessage({ id: 'paginas.gente.paralax.title' }))}</h1>
//                   <div className='d-flex'>
//                     <img src={selo} className='pr-3' width={'100'}></img>
//                     <p className="line-height-2 text-white">
//                       {parse(intl.formatMessage({ id: 'paginas.gente.paralax.p' }))}
//                     </p>
//                   </div>

//                 </div>
//               </div>
//             </div>
//           </div>
//         </BackgroundImg>
//         <div className="gradient-blue-to-green"></div>
//       </div>
//     </>
//   )
// }

const WorkLwart = ({ woman, womanMobile }) => {
  const controls = useAnimation()
  const [ref, inView] = useInView()
  const intl = useIntl()

  useEffect(() => {
    if (inView) {
      controls.start('visible')
    }
  }, [controls, inView])
  return <>
    <motion.div ref={ref} animate={controls} className="container" id="WorkLwart">
      <div className="row px-4 mx-0">
        <div className="col-md-7 px-0 ml-lg-n5 mr-lg-2 pt-lg-0 pt-5">
          <GatsbyImage
            image={woman}
            alt="Funcionária Lwart"
            className="img-fluid ml-lg-n2 d-none d-lg-block" />
          <GatsbyImage
            image={womanMobile}
            alt="Funcionária Lwart"
            className="img-fluid ml-lg-n2 d-block d-lg-none" />
        </div>
        <div className="col-md-5 d-grid align-items-center py-5 px-0">
          <header className="align-self-end">
            <motion.h2 initial="hidden"
              transition={{ duration: 1, delay: 0.5 }}
              variants={{
                visible: { opacity: 1, x: 0 },
                hidden: { opacity: 0, x: 150 }
              }} className="text-lwart-blue mb-4" >{parse(intl.formatMessage({ id: 'paginas.gente.work_lwart.title1' }))}</motion.h2>
          </header>
          <div>
            <motion.div initial="hidden"
              transition={{ duration: 2, delay: 0.5 }}
              variants={{
                visible: { opacity: 1, x: 0 },
                hidden: { opacity: 0 }
              }}>
              <p className="line-height-normal mb-4">{parse(intl.formatMessage({ id: 'paginas.gente.work_lwart.p1' }))}</p>
              <p className="line-height-normal mb-4">{parse(intl.formatMessage({ id: 'paginas.gente.work_lwart.p2' }))}</p>
              <p className="line-height-normal mb-4">{parse(intl.formatMessage({ id: 'paginas.gente.work_lwart.p3' }))}</p>
              <p className="line-height-normal mb-4">{parse(intl.formatMessage({ id: 'paginas.gente.work_lwart.p4' }))}</p>
            </motion.div>
            <div className="d-flex align-items-center mt-5">
              <img src={icon1} className="bg-lwart-green w-20 p-2 rounded-circle above" />
              <a href="https://trabalheconosco.vagas.com.br/grupolwart" target="_blank" rel="noopener noreferrer" className="availableJobs font-Spinnaker">{parse(intl.formatMessage({ id: 'paginas.gente.work_lwart.link' }))}</a>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  </>
}

const CorporationParalax = (data) => {
  const controls = useAnimation()
  const [ref, inView] = useInView()
  const intl = useIntl()

  useEffect(() => {
    if (inView) {
      controls.start('visible')
    }
  }, [controls, inView])
  const image = getImage(data.paralax2)
  const bgImage = convertToBgImage(image)
  const imageMobile = getImage(data.paralax2Mobile)
  const bgImageMobile = convertToBgImage(imageMobile)
  return (
    <>
      <motion.div ref={ref} animate={controls}>
        <motion.div className="CorporationParalax d-none d-lg-block">
          <BackgroundImg
            {...bgImage}
            Tag="div"
            className="paralaxImageCorporation"
          >
            <div className="section-blue d-none d-lg-block">
              <div className="container h-100 w-100">
                <div className="row h-100 mw-100">
                  <div className="col-6 d-flex align-items-center h-100 ">
                    <div className=" w-65 ml-auto">
                      <motion.div initial="hidden"
                        transition={{ duration: 1.5 }}
                        variants={{
                          visible: { opacity: 1, x: 0 },
                          hidden: { opacity: 0, x: -150 }
                        }}>
                        <span className="text-uppercase text-lwart-green font-Spinnaker">{parse(intl.formatMessage({ id: 'paginas.gente.corporation_paralax.span1' }))}</span>
                        <h2 className="text-white my-3">{parse(intl.formatMessage({ id: 'paginas.gente.corporation_paralax.title' }))}</h2>
                      </motion.div>
                      <motion.div initial="hidden"
                        transition={{ duration: 2, delay: 0.5 }}
                        variants={{
                          visible: { opacity: 1 },
                          hidden: { opacity: 0 }
                        }} className="w-65">
                        <p className="text-white line-height-normal">
                          {parse(intl.formatMessage({ id: 'paginas.gente.corporation_paralax.p1' }))}
                        </p>
                        <p className="text-white line-height-normal">{parse(intl.formatMessage({ id: 'paginas.gente.corporation_paralax.p2' }))}</p>
                      </motion.div>
                    </div>
                  </div>
                  <div className="offset-6"></div>
                </div>
              </div>
            </div>
            <div className="h-100 mw-100 d-flex flex-column justify-content-end mx-0 d-block d-lg-none">
              <div className="d-flex align-items-center section-blue py-5 px-4">
                <div className="">
                  <span className="text-uppercase text-lwart-green">{parse(intl.formatMessage({ id: 'paginas.gente.corporation_paralax.span1' }))}</span>
                  <h2 className="text-white my-3">{parse(intl.formatMessage({ id: 'paginas.gente.corporation_paralax.title' }))}</h2>
                  <p className="text-white line-height-normal">
                    {parse(intl.formatMessage({ id: 'paginas.gente.corporation_paralax.p1' }))}
                  </p>
                  <p className="text-white line-height-normal">{parse(intl.formatMessage({ id: 'paginas.gente.corporation_paralax.p2' }))}</p>
                </div>
              </div>
            </div>
          </BackgroundImg>
          <div className="gradient-blue-to-green"></div>
        </motion.div>
        <motion.div className="CorporationParalax d-block d-lg-none" ref={ref} animate={controls}>
          <BackgroundImg
            {...bgImageMobile}
            Tag="div"
            className="paralaxImageCorporation"
          >
            {/* <div className="section-blue d-none d-lg-block">
              <div className="container h-100 w-100">
                <div className="row h-100 mw-100">
                  <div className="col-6 d-flex align-items-center h-100 ">
                    <div className=" w-65 ml-auto">
                      <motion.div initial="hidden"
                        transition={{ duration: 1.5 }}
                        variants={{
                          visible: { opacity: 1, x: 0 },
                          hidden: { opacity: 0, x: -150 }
                        }}>
                        <span className="text-uppercase text-lwart-green font-Spinnaker">Educação Corporativa</span>
                        <h2 className="text-white my-3">Crescer Juntos</h2>
                      </motion.div>
                      <motion.div initial="hidden"
                        transition={{ duration: 2, delay: 0.5 }}
                        variants={{
                          visible: { opacity: 1 },
                          hidden: { opacity: 0 }
                        }} className="w-65">
                        <p className="text-white line-height-normal">
                          A Educação Corporativa é um processo educativo focado na aquisição de conhecimentos necessários para o entendimento dos processos e atuação eficaz no trabalho, reforçando os Valores e a Cultura da organização. É um modelo de aprendizagem que faz com que os colaboradores sejam protagonistas do seu desenvolvimento.
                        </p>
                        <p className="text-white line-height-normal">A missão da “Crescer Juntos “ é apoiar o desenvolvimento das pessoas para produzir resultados sustentáveis, hoje e no futuro.</p>
                      </motion.div>
                    </div>
                  </div>
                  <div className="offset-6"></div>
                </div>
              </div>
            </div> */}
            <div className="h-100 mw-100 d-flex flex-column justify-content-end mx-0 d-block d-lg-none">
              <div className="d-flex align-items-center section-blue py-5 px-4">
                <div className="">
                  <span className="text-uppercase text-lwart-green">{parse(intl.formatMessage({ id: 'paginas.gente.corporation_paralax.span1' }))}</span>
                  <h2 className="text-white my-3">{parse(intl.formatMessage({ id: 'paginas.gente.corporation_paralax.title' }))}</h2>
                  <p className="text-white line-height-normal">
                    {parse(intl.formatMessage({ id: 'paginas.gente.corporation_paralax.p1' }))}
                  </p>
                  <p className="text-white line-height-normal">{parse(intl.formatMessage({ id: 'paginas.gente.corporation_paralax.p2' }))}</p>
                </div>
              </div>
            </div>
          </BackgroundImg>
          <div className="gradient-blue-to-green"></div>
        </motion.div>
      </motion.div>
    </>
  )
}

const GuiadaValores = () => {
  const intl = useIntl()

  return (
    <>
      <section className="bg-lwart-lightblue2 full m-0 cracha pt-lg-0 pt-4">
        <div className="container pb-lg-0 px-0">
          <div className="row m-0 px-4">
            <div className="textSolucoes col-lg-5 offset-1 my-0 px-0 pl-lg-5 text-lg-left text-white">
              <div>
                <h2 className="text-white ">{parse(intl.formatMessage({ id: 'paginas.gente.guiada_valores.title' }))}</h2>
                <p className="text-white py-2 font-Spinnaker">{parse(intl.formatMessage({ id: 'paginas.gente.guiada_valores.p1' }))}
                </p>
                <p className="text-white font-Spinnaker">{parse(intl.formatMessage({ id: 'paginas.gente.guiada_valores.p2' }))}
                </p>
                <label className="w-100 my-4">
                  <a href="https://trabalheconosco.vagas.com.br/grupolwart" target="_blank" rel="noopener noreferrer" className="buttonCurriculo font-Spinnaker">{parse(intl.formatMessage({ id: 'paginas.gente.guiada_valores.link' }))}</a>
                </label>
                <p className="text-white pt-2" style={{ fontSize: '0.75rem', lineHeight: '1.5' }} >{parse(intl.formatMessage({ id: 'paginas.gente.guiada_valores.p4' }))}
                </p>
              </div>
            </div>
            <div className="col-lg-6 pl-0 crachaGente">
              {intl.locale === 'pt'
                ? <img src={cracha} alt='imagem crachá' />
                : intl.locale === 'en'
                  ? <img src={crachaEnglish} alt='image card' />
                  : <img src={crachaEspanhol} alt='imagen cartón' />
              }
            </div>

          </div>
        </div>
      </section>
      <div className="gradient-blue-to-green"></div>
    </>
  )
}

const CodigoConduta = (data) => {
  const controls = useAnimation()
  const [ref, inView] = useInView()
  const [showMore, setShowMore] = useState()
  const intl = useIntl()

  const ShowMore = (id) => {
    setShowMore(id)
  }

  useEffect(() => {
    if (inView) {
      controls.start('visible')
    }
  }, [controls, inView])
  return <>
    <motion.div className="container overflow-auto pb-lg-5" ref={ref} animate={controls}>
      <div className="row justify-content-center align-items-center px-4">
        <div className="col-lg-4 p-0 d-flex justify-content-lg-end justify-content-center mt-lg-1 mt-5 mb-lg-0 mb-4 position-relative">
          {intl.locale === 'pt'
            ? <>
              <GatsbyImage
                image={data.condutaMobile}
                imgStyle={{ objectPosition: '100% 100%' }}
                className="w-80 h-90 d-lg-block d-none mt-3" />
              <GatsbyImage image={data.condutaMobile} className="w-90 d-lg-none d-block" />
            </>
            : intl.locale === 'en' ? <>
              <GatsbyImage
                image={data.condutaMobileEnglish}
                imgStyle={{ objectPosition: '100% 100%' }}
                className="w-80 h-90 d-lg-block d-none mt-3" />
              <GatsbyImage image={data.condutaMobileEnglish} className="w-90 d-lg-none d-block" />
            </>
              : <>
                <GatsbyImage
                  image={data.condutaEspanhol}
                  imgStyle={{ objectPosition: '100% 100%' }}
                  className="w-80 h-90 d-lg-block d-none mt-3" />
                <GatsbyImage image={data.condutaEspanhol} className="w-90 d-lg-none d-block" />
              </>
          }
          <div className='position-absolute homeInfo' onMouseEnter={() => ShowMore('codigoConduta')} onMouseLeave={() => ShowMore()}>
            <p className='text-white text-center'>{parse(intl.formatMessage({ id: 'paginas.gente.codigo_conduta.blue_circle' }))}</p>
          </div>
          {showMore === 'codigoConduta' &&
            <div className='position-absolute text-center genteInfoHover'>
              <img src={paper} />
              <p className='text-white pt-1'>
                {parse(intl.formatMessage({ id: 'paginas.gente.codigo_conduta.green_circle' }))}
              </p>
            </div>
          }
        </div>
        <div className="col-lg-4 d-flex flex-column mt-lg-5 mb-5">
          <img src={icon2} className="bg-lwart-green w-25 p-1 mb-3 rounded-circle above d-none d-lg-block" />
          <motion.h2 initial="hidden"
            transition={{ duration: 1, delay: 0.5 }}
            variants={{
              visible: { opacity: 1, x: 0 },
              hidden: { opacity: 0, x: 150 }
            }} className="text-lwart-blue mb-3 line-height-normal">{parse(intl.formatMessage({ id: 'paginas.gente.codigo_conduta.title1' }))}</motion.h2>
          <p className="line-height-normal mb-lg-3 mb-4 pr-lg-5">{parse(intl.formatMessage({ id: 'paginas.gente.codigo_conduta.p1' }))}</p>
          <p className="line-height-normal mb-lg-3 mb-4 pr-lg-5">{parse(intl.formatMessage({ id: 'paginas.gente.codigo_conduta.p2' }))}</p>
          <a className="align-self-center align-self-lg-start d-none d-lg-block" download href={data.data.arquivo.arquivo.file.url} target="_blank" rel="noreferrer noopener"><button className="btn btn-primary btn-sustentabilidade">{parse(intl.formatMessage({ id: 'paginas.gente.codigo_conduta.link' }))}</button></a>
          <div className="d-flex align-items-center d-block d-lg-none">
            <img src={icon2} className="bg-lwart-green w-20 p-2 p-md-3 rounded-circle above" />
            <a className="align-self-center align-self-lg-start ml-n3" download href={data.data.arquivo.arquivo.file.url} target="_blank" rel="noreferrer noopener"><button className="btn btn-primary btn-sustentabilidade">{parse(intl.formatMessage({ id: 'paginas.gente.codigo_conduta.link' }))}</button></a>
          </div>
        </div>
        <div className='col-lg-4 d-flex flex-column pt-lg-5 mt-lg-5 mb-5'>
          <img src={canalDenuncias} className='w-25 mb-3 d-none d-lg-block above' />
          <motion.h2 initial="hidden"
            transition={{ duration: 1, delay: 0.5 }}
            variants={{
              visible: { opacity: 1, x: 0 },
              hidden: { opacity: 0, x: 150 }
            }} className="text-lwart-blue mb-3 line-height-normal">{parse(intl.formatMessage({ id: 'paginas.gente.canal_denuncias.title1' }))}</motion.h2>
          <p className="line-height-normal mb-lg-3 mb-4 pr-lg-5">{parse(intl.formatMessage({ id: 'paginas.gente.canal_denuncias.p1' }))}</p>
          <a className="align-self-center align-self-lg-start d-none d-lg-block" href='https://www.canalconfidencial.com.br/lwart/' target="_blank" rel="noreferrer noopener"><button className="btn btn-primary btn-sustentabilidade">{parse(intl.formatMessage({ id: 'paginas.gente.canal_denuncias.link' }))}</button></a>
          <p className="line-height-normal mb-lg-3 mb-4 pr-lg-5 mt-lg-3">{parse(intl.formatMessage({ id: 'paginas.gente.canal_denuncias.telefone' }))}0800-5917182.</p>
          <div className="d-flex align-items-center d-block d-lg-none">
            <img src={canalDenuncias} className="w-20 above" />
            <a className="align-self-center align-self-lg-start ml-n3" href='https://www.canalconfidencial.com.br/lwart/' target="_blank" rel="noreferrer noopener"><button className="btn btn-primary btn-sustentabilidade">{parse(intl.formatMessage({ id: 'paginas.gente.canal_denuncias.link' }))}</button></a>
          </div>
        </div>
      </div>
    </motion.div>
  </>
}

const Gente = ({ data }) => {
  console.log(data)
  return <>
    <Pagina pagina={data.pagina} />
    {/* <Paralax paralax={data.paralax.childImageSharp.gatsbyImageData} paralaxMobile={data.paralaxMobile.childImageSharp.gatsbyImageData} /> */}
    <GuiadaValores />
    <WorkLwart woman={data.woman.childImageSharp.gatsbyImageData} womanMobile={data.womanMobile.childImageSharp.gatsbyImageData} />
    <CorporationParalax paralax2={data.paralax2.childImageSharp.gatsbyImageData} paralax2Mobile={data.paralax2Mobile.childImageSharp.gatsbyImageData} />
    <CodigoConduta conduta={data.conduta.childImageSharp.gatsbyImageData} condutaMobile={data.condutaMobile.childImageSharp.gatsbyImageData}
      condutaMobileEnglish={data.condutaMobileEnglish.childImageSharp.gatsbyImageData} data={data} condutaEspanhol={data.condutaEspanhol.childImageSharp.gatsbyImageData} />
  </>
}

export default Gente
export const query = graphql`{
  arquivo: contentfulDocumentos(categoria: {eq: "gente"}) {
    arquivo {
      file {
        url
      }
    }
  }
  pagina: contentfulPagina(path: {eq: "/gente/"}) {
    path
    metaTitle
    metaDescription
    pageTitle
    tags
    ogImage {
      localFile {
        publicURL
      }
    }
  }
  paralax: file(relativePath: {eq: "gente/paralax.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  paralaxMobile: file(relativePath: {eq: "gente/gente.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  paralax2: file(relativePath: {eq: "gente/paralax-2.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  paralax2Mobile: file(relativePath: {eq: "gente/paralax-2-mobile.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  woman: file(relativePath: {eq: "gente/menina.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  womanMobile: file(relativePath: {eq: "gente/meninaMobile.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, width: 548, height: 700, layout: CONSTRAINED)
    }
  }
  conduta: file(relativePath: {eq: "gente/Conduta2.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  condutaMobile: file(relativePath: {eq: "gente/Conduta2-mobile.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  condutaMobileEnglish: file(relativePath: {eq: "gente/condutaMobile-english.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  condutaEspanhol: file(relativePath: {eq: "gente/conduta-espanhol.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
}
`
